import { Controller } from "@hotwired/stimulus"
import "ninja-keys"

const list = []; 

export default class extends Controller {

  connect() {
    list.push(
      {
        id: 'Dashboard',
        title: 'Turnout Dashboard',
        hotkey: 'ctrl+d, cmd+d',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-ingido-700 ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"> <path stroke-linecap="round" stroke-linejoin="round" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /> </svg>`,
        section: 'Pages',
        handler: () => {
          Turbo.visit("/dashboard")
        }
      },
      {
        id: 'Districts',
        title: 'Districts',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>`,
        children: ['Ajax'],
        handler: () => {
          // open menu if closed. Because you can open directly that menu from it's hotkey
          ninja.open({ parent: 'Districts' });
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'Results',
        title: 'Election Results',
        hotkey: 'ctrl+e, cmd+e',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-ingido-700 ninja-icon"><path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" /></svg>`,
        section: 'Pages',
        handler: () => {
          Turbo.visit("/dashboard/results")
        }
      },
    )
    
    this.loadRidings()
    setTimeout(() => this.setData(), 1000)
  }

  setData() {
    this.element.data = list;   
  }


  loadRidings() {
    fetch('/riding_search', {
      headers: { accept: 'application/json'}
    }).then((response) => response.json())
    .then(data => { 
     var ridingArray = Object.values(data)[0]
     ridingArray.forEach(riding => {
      list.push(this.ridingTemplate(riding))
    });
   });  
  } 

  disconnect() {
    list.length = 0;
  }


  ridingTemplate(riding) {
    return {
      id: `${riding.name_en}`,
      title: `${riding.riding_code} - ${riding.name_en}`,
      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>`,
      parent: 'Ridings',
      handler: () => {
        Turbo.visit(`/ridings/${riding.riding_code}`)
      }
    }
  }


}